import { useEffect, useRef, useState } from "react"
import * as Cesium from "cesium";
import useOrbit from "./useOrbit";
import useUrlParams from "./useUrlParams";
import {
    getAoiPacket,
    getGroundStationPacket,
    getGroundTargetPacket,
    getOrbitPacket,
    getSatellitePackete,
    groundStationToSatelliteConnection,
    removeDataSourceByName,
    satelliteToGroundTargetConnection
} from "./cesiumUtils";
import useViewer from "./useViewer";
import { get_formated_time } from "../utils";

const useCesium = () => {
    const { orbitData } = useOrbit()
    const cesiumContainerRef = useRef<HTMLDivElement | null>(null)
    const { sat_id, scenario_id, dashboard } = useUrlParams()
    const { setViewer, current_simulation_time } = useViewer()
    const [map_viewer, set_map_viewer] = useState<Cesium.Viewer | null>(null)
    const [groundTargets, setGroundTargets] = useState<any[]>([])

    let Imaginary = new Cesium.MapboxStyleImageryProvider({
        styleId: 'dark-v10',
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
    });

    const update_base_map_layer = (map_viewer, baseMapOption) => {
        switch (baseMapOption) {
            case "Mapbox Satellite": {
                var imaginary = new Cesium.MapboxStyleImageryProvider({
                    styleId: 'satellite-v9',
                    accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
                });
                map_viewer.imageryLayers.addImageryProvider(imaginary);
                break;
            }
            case "Mapbox Dark": {
                var imaginary = new Cesium.MapboxStyleImageryProvider({
                    styleId: 'dark-v10',
                    accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
                });
                map_viewer.imageryLayers.addImageryProvider(imaginary);
                break;
            }
            case "Mapbox Light": {
                var imaginary = new Cesium.MapboxStyleImageryProvider({
                    styleId: 'light-v10',
                    accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
                });
                map_viewer.imageryLayers.addImageryProvider(imaginary);
                break;
            }
        }

    }


    useEffect(() => {
        if (cesiumContainerRef.current && scenario_id) {
            const cesium_viewer = new Cesium.Viewer(cesiumContainerRef.current, {
                requestRenderMode: true,
                shouldAnimate: true,
                animation: false, // Disable the default animation widget
                baseLayerPicker: false, // Disable the default base layer picker
                timeline: false, // Disable the timeline
                geocoder: false, // Disable the geocoder
                homeButton: false, // Disable the home button
                navigationHelpButton: false, // Disable the navigation help button
                sceneModePicker: false, // Disable the scene mode picker
                selectionIndicator: false, // Disable the selection indicator
                fullscreenButton: false,
                imageryProvider: Imaginary,
            });

            const map_details = JSON.parse(sessionStorage.getItem('map_details') || '{}');
            if (map_details[scenario_id]) {
                if (map_details?.[scenario_id]?.["Eclipse"] && !cesium_viewer.scene.globe.enableLighting) {
                    cesium_viewer.scene.globe.enableLighting = cesium_viewer.scene.globe.enableLighting
                } else if (!map_details?.[scenario_id]?.["Eclipse"] && cesium_viewer.scene.globe.enableLighting) {
                    cesium_viewer.scene.globe.enableLighting = !cesium_viewer.scene.globe.enableLighting
                }
                map_details?.[scenario_id]?.["View"] === '2D' ? cesium_viewer.scene.morphTo2D(2) : cesium_viewer.scene.morphTo3D(2);
                update_base_map_layer(cesium_viewer, map_details?.[scenario_id]?.["Base Map"]);
            } else {
                cesium_viewer.scene.morphTo2D(2)
                var imaginary = new Cesium.MapboxStyleImageryProvider({
                    styleId: 'dark-v10',
                    accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
                });
                cesium_viewer.imageryLayers.addImageryProvider(imaginary);
                let details = {
                    ...map_details,
                    [scenario_id]: {
                        "View": '2D',
                        "Base Map": 'Mapbox Dark',
                        "Eclipse": false,
                    }
                }
                sessionStorage.setItem('map_details', JSON.stringify(details));
            }
            cesium_viewer.scene.globe.baseColor = Cesium.Color.TRANSPARENT
            cesium_viewer.scene.globe.showGroundAtmosphere = false;
            cesium_viewer.clock.currentTime = cesium_viewer.clock.startTime;
            cesium_viewer.clock.shouldAnimate = true;
            cesium_viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
            cesium_viewer.scene.screenSpaceCameraController.enableRotate = true;
            cesium_viewer.scene.screenSpaceCameraController.enableZoom = true;
            cesium_viewer.scene.screenSpaceCameraController.enableTilt = true;
            cesium_viewer.scene.screenSpaceCameraController.enableLook = true;
            cesium_viewer.scene.highDynamicRange = false;
            cesium_viewer.scene.screenSpaceCameraController.inertiaZoom = 40000000;
            cesium_viewer.scene.screenSpaceCameraController.maximumZoomDistance = 35500000;
            set_map_viewer(cesium_viewer)
            setViewer(cesium_viewer)

            setTimeout(() => {
                cesium_viewer.scene.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(0, 0, 35500000),
                    orientation: {
                        heading: Cesium.Math.toRadians(0.0),
                        pitch: Cesium.Math.toRadians(0.0),
                        roll: Cesium.Math.toRadians(0.0)
                    }
                });
            }, 1000)
        }

    }, [scenario_id])

    useEffect(() => {
        if (orbitData && map_viewer) {
            let document = orbitData.documentMetadata
            let satellites = orbitData?.['satellite'] ? orbitData?.['satellite'] : []
            let aois = orbitData?.['areaOfInterest'] ? orbitData?.['areaOfInterest'] : []
            let groundTargets = orbitData?.['groundTarget'] ? orbitData?.['groundTarget'] : []
            let groundStations = orbitData?.['groundStation'] ? orbitData?.['groundStation'] : []
            let satToGsConnection = orbitData?.['satToGsConnection'] ? orbitData?.['satToGsConnection'] : []
            let satToGTConnection = orbitData?.['satToGTConnection'] ? orbitData?.['satToGTConnection'] : []
            createPackets(document, satellites, aois, groundTargets, groundStations, satToGsConnection, satToGTConnection)
            setGroundTargets(groundTargets)
        }
    }, [orbitData, map_viewer])

    const createPackets = (document, satellites, aois, groundTargets, groundStations, satToGsConnection, satToGTConnection) => {
        if (!map_viewer || !map_viewer.dataSources) return

        // clean the previous czml or data source
        // map_viewer.dataSources.removeAll();
        let czmlPackages: any[] = []
        let satelliteczml: any[] = [];
        let groundStationczml: any[] = [];
        let aoiCzml: any[] = [];
        let groundTargetCzml: any[] = [];
        let satToGsConnections: any[] = [];
        let satToGTConnections: any[] = [];
        let orbitFlow: any[] = [];


        satelliteczml = getSatellitePackete(document, satellites)  // Create the satellite packet
        orbitFlow = getOrbitPacket(satellites);
        groundStationczml = getGroundStationPacket(satellites, groundStations, false)  // Create the Ground statio packet
        aoiCzml = getAoiPacket(satellites, aois)  // Create the Aoi Packet
        groundTargetCzml = getGroundTargetPacket(satellites, groundTargets)  // Create the Ground Target Packet
        satToGsConnections = groundStationToSatelliteConnection(satToGsConnection)
        satToGTConnections = satelliteToGroundTargetConnection(satToGTConnection)


        if (satelliteczml?.length > 0) {
            czmlPackages.push(...satelliteczml)
        } if (groundStationczml?.length > 0) {
            czmlPackages.push(...groundStationczml)
        } if (aoiCzml?.length > 0) {
            czmlPackages.push(...aoiCzml)
        } if (groundTargetCzml?.length > 0) {
            czmlPackages.push(...groundTargetCzml)
        } if (satToGsConnections?.length > 0) {
            czmlPackages.push(...satToGsConnections)
        } if (satToGTConnections?.length > 0) {
            czmlPackages.push(...satToGTConnections)
        }
        if (orbitFlow?.length > 0) {
            removeDataSourceByName(map_viewer, 'Orbit_Object')
            map_viewer.dataSources.add(Cesium.CzmlDataSource.load(orbitFlow));
        }
        if (czmlPackages.length > 0) {
            removeDataSourceByName(map_viewer, 'Satellite Orbit')
            map_viewer.dataSources.add(Cesium.CzmlDataSource.load(czmlPackages));
            if (dashboard === 'summary' && current_simulation_time > 0) {
                if (czmlPackages[0]?.clock) {
                    czmlPackages[0].clock.currentTime = get_formated_time(current_simulation_time / 1000)
                }
            }
            map_viewer.dataSources.add(Cesium.CzmlDataSource.load(czmlPackages));
            map_viewer.scene.requestRender();
        }
    }


    return {
        map_viewer,
        cesiumContainerRef,
        groundTargets
    }
}

export default useCesium