import { getEnvVar } from '../../envUtils.ts';
import ApiService from '../../service/ApiService.js';
import * as ucpActions from '../../store/UnifiedControlPlaneSlice.js';

const ORDERS_URL = 'oms/orders';

const getOrders = () => async (dispatch) => {
    dispatch(ucpActions.getOrdersRequest());
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}${ORDERS_URL}`);
        dispatch(ucpActions.getOrdersSuccess(response.data));
    } catch (error) {
        dispatch(ucpActions.getOrdersFailure(error.message));
        console.error('Error while getting orders:', error);
    }
};

const createOrders = (body, callback) => async (dispatch) => {
    dispatch(ucpActions.createOrderRequest);
    try {
        const response = await ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}${ORDERS_URL}`, body);
        dispatch(ucpActions.createOrderSuccess(response.data));
        if (callback) {
            callback();
        }
    } catch (error) {
        dispatch(ucpActions.createOrderFailure());
        console.error('Error while creating order:', error);
    }
};

const updateSingleOrder = (orderId, action, callback) => async (dispatch) => {
    const body = {
        action,
    }
    dispatch(ucpActions.updateOrderRequest());
    try {
        const response = await ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}${ORDERS_URL}/${orderId}/action`, body);
        dispatch(ucpActions.updateOrderSuccess(response));
        if (callback) {
            callback();
        }
    } catch (error) {
        dispatch(ucpActions.updateOrderFailure());
        console.error('Error while updating order:', error);
    }
};

const updateOrders = (orderIDs, missionID, action, callback) => async (dispatch) => {
    const body = {
        orderIDs,
        action,
    }
    dispatch(ucpActions.updateOrderRequest());
    try {
        const response = await ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}oms/${missionID}/schedules/action`, body);
        dispatch(ucpActions.updateOrderSuccess(response));
        if (callback) {
            callback();
        }
    } catch (error) {
        dispatch(ucpActions.updateOrderFailure());
        console.error('Error while updating order:', error);
    }
};

const deleteOrders = (orderId, callback) => async (dispatch) => {
    dispatch(ucpActions.deleteOrderRequest());
    try {
        const response = await ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}${ORDERS_URL}/${orderId}`);
        dispatch(ucpActions.deleteOrderSuccess(response));
        if (callback) {
            callback();
        }
    } catch (error) {
        dispatch(ucpActions.deleteOrderFailure());
        console.error('Error while deleting orders:', error);
    }
};

const generateSchedule = (scenarioId, missionID, callback) => async (dispatch) => {
    const body = {};
    dispatch(ucpActions.genrateScheduleRequest());
    try {
        const response = await ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}oms/${missionID}/schedules/generate?scenarioID=${scenarioId}`, body);
        dispatch(ucpActions.genrateScheduleSuccess(response));
        if (callback) {
            callback();
        }
    } catch (error) {
        dispatch(ucpActions.genrateScheduleFailure());
        console.error('Error while generating schedule:', error);
    }
};

const getSchedule = (scenarioId, missionID, callback) => async (dispatch) => {
    dispatch(ucpActions.getScheduleRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}oms/${missionID}/schedules/${missionID}`);
        dispatch(ucpActions.getScheduleSuccess(response.data));
        if (callback) {
            callback();
        };
    } catch (error) {
        dispatch(ucpActions.getScheduleFailure());
        console.error(error);
    }
};

const getConstellationsFromMM = (missionId, scenarioid) => async (dispatch) => {
    dispatch(ucpActions.getConstellationRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/constellation/all/orbit?missionid=${missionId}&scenarioid=${scenarioid}`);
        dispatch(ucpActions.getConstellationSuccess(response.data))
    } catch (error) {
        dispatch(ucpActions.getConstellationFailure())
        console.log(error)
    }
}

const getGroundStationsFromMission = (missionId) => async (dispatch) => {
    dispatch(ucpActions.getGroundStationRequest())
    try {
        // const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation`);
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/groundStationDetails/${missionId}`);
        dispatch(ucpActions.getGroundStationSuccess(response.data))
    } catch (error) {
        dispatch(ucpActions.getGroundStationFailure())
        console.error(error)
    }
}

const getMissionMoellingModels = (mission_id) => async (dispatch) => {
    dispatch(ucpActions.getScenarioIdRequest())
    try {
        const response = await ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}mission-simulation/model/all?missionid=${mission_id}`)
        dispatch(ucpActions.getScenarioIdSuccess(response.data?.[0]?.['scenario_id']))
    } catch (error) {
        console.error(error)
        dispatch(ucpActions.getConstellationFailure())
    }
}

const UnifiedControlPlaneService = {
    getMissionMoellingModels,
    getOrders,
    createOrders,
    updateSingleOrder,
    updateOrders,
    deleteOrders,
    generateSchedule,
    getSchedule,
    getConstellationsFromMM,
    getGroundStationsFromMission
};

export default UnifiedControlPlaneService;
