import { useEffect, useState } from "react";
import "./PreviewSchedule.css";
import { useDispatch, useSelector } from "react-redux";
import UnifiedControlPlaneService from "../../UnifiedControlPlane.service";
import { epochDate } from "../Utils/HelperFunctions";

const PreviewSchedule = ({ mission_id, scenarioId, previewOrders }) => {

  // const [data, setData] = useState([
  //   { start_epoch_sec: "1734092371", order_name: "Order B", assignment_label: "Task 3", assignment_type: 'Ground' },
  //   { start_epoch_sec: "1734092370", order_name: "Order E", assignment_label: "Task 4", assignment_type: 'Order' },
  //   { start_epoch_sec: "1734092571", order_name: "Order C", assignment_label: "Task 5", assignment_type: 'Ground' },
  //   { start_epoch_sec: "1734992371", order_name: "Order A", assignment_label: "Task 2", assignment_type: 'Order' },
  //   { start_epoch_sec: "1734092971", order_name: "Order D", assignment_label: "Task 1", assignment_type: 'Ground' },
  // ]);

  const dispatch = useDispatch();
  const fetchingSchedule = useSelector(state => state.unifiedControlPlane.fetchingSchedule);
  const scheduleData = useSelector(state => state.unifiedControlPlane.scheduleData);

  const [showReviewSchedulePopup, setShowReviewSchedulePopup] = useState(false);

  const orderUpdateSuccessCallback = () => {
    dispatch(UnifiedControlPlaneService.getOrders());
  };

  const updatePeviewOrders = (action) => {
    dispatch(UnifiedControlPlaneService.updateOrders(previewOrders, mission_id, action, orderUpdateSuccessCallback));
    setShowReviewSchedulePopup(false)
  };

  const requestSchedule = () => {
    dispatch(UnifiedControlPlaneService.generateSchedule(scenarioId, mission_id, orderUpdateSuccessCallback));
    setShowReviewSchedulePopup(false)
  };

  const PreviewSchedule = () => {
    if(showReviewSchedulePopup === true) {
      setShowReviewSchedulePopup(false);
    } else {
      dispatch(UnifiedControlPlaneService.getSchedule(scenarioId, mission_id));
      setShowReviewSchedulePopup(true);
    }
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    if (scheduleData && Array.isArray(scheduleData.assignments)) {
      setData(scheduleData.assignments);
    }
  }, [scheduleData]);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const displayScheduleReview = () => {
    return (
      <div className="preview-schedule">
        <div className="preview-schedule-header">
          <div className="preview-schedule-title">
            <img className="GenerateScehduleIcon" src={require("../../ImgAssets/GenerateSchedule.svg").default} />
            <p className="preview-schedule-title-text">Schedule Review</p>
          </div>
          <div className="preview-schedule-orders-summary">
            <div className="preview-schedule-orders-text">
              {scheduleData.evaluated_orders_count} Orders Scheduled
            </div>
            <div className="preview-schedule-close-action"
              onClick={() => setShowReviewSchedulePopup(false)}
            >
              <img className="CloseIcon" src={require("../../ImgAssets/CloseIconWhite.svg").default} />
            </div>
          </div>
        </div>
        {!fetchingSchedule ?
          <>
            <div className="schedule-table-section">
              <table className="schedule-table">
                <thead className="schedule-table-header">
                  <tr className="schedule-table-row">
                    <th className="schedule-table-column-width" onClick={() => handleSort("start_epoch_sec")}>Time</th>
                    <th className="schedule-table-column-width" onClick={() => handleSort("order_name")}>Order Name</th>
                    <th className="schedule-table-column-width" onClick={() => handleSort("assignment_label")}>Assignment</th>
                    <th className="schedule-table-column-width" onClick={() => handleSort("satellite_name")}>Satellite</th>
                  </tr>
                </thead>
                <tbody className="schedule-table-content">
                  {data.map((row, index) => (
                    <tr key={index} className="schedule-table-row">
                      <td className="time-column schedule-table-column-width">{epochDate(row.start_epoch_sec)}</td>
                      <td className="schedule-table-column schedule-table-column-width">
                        {row.order_name ? row.order_name : '-'}
                      </td>
                      <td className="schedule-table-column schedule-table-column-width">
                        <span className="assignment-icon">
                          <img src={row.assignment_type === 'Order' ? require("../../ImgAssets/GenerateSchedule.svg").default :
                            require("../../ImgAssets/GroundSchedule.svg").default}
                          />
                        </span>
                        {row.assignment_label}
                      </td>
                      <td className="schedule-table-column schedule-table-column-width">{row.satellite_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="preview-schedule-actions">
              <div className="preview-schedule-actions-group-single">
                <button className="preview-schedule-button ps-btn-dark" onClick={() => requestSchedule()}>
                  <img className="RegenerateScehduleIcon" src={require("../../ImgAssets/GenerateSchedule.svg").default} />
                  Regenerate
                </button>
              </div>
              <div className="preview-schedule-actions-group">
                <button className="preview-schedule-button ps-btn-dark" onClick={() => updatePeviewOrders('Reject')}>Reject</button>
                <button className="preview-schedule-button ps-btn-light" onClick={() => updatePeviewOrders('Approve')}>Approve</button>
              </div>
            </div>
          </> :
          <div className="loading-schedule-msg">Loading schedules...</div>
        }
      </div>
    )
  };

  return (
    <>
      {showReviewSchedulePopup && displayScheduleReview()}
      <>
        <div className={showReviewSchedulePopup ?
          'ucpFeaturesBar scheduleBar scheduleBarOpen' :
          'ucpFeaturesBar scheduleBar scheduleBarClose'} style={{ width: "auto" }}>
          <button className="scheduleBtn" onClick={() => PreviewSchedule()}>
            {<img src={require("../../ImgAssets/GenerateSchedule.svg").default} />}
            {/* {isFeaturesFilter && <img src={require("../ImgAssets/CloseIconWhite.svg").default} />} */}
          </button>
        </div>
      </>
    </>
  );

};

export default PreviewSchedule;