import { createSlice } from '@reduxjs/toolkit';
import { ORDER_STATUS, ORDER_STATUS_SERVER } from '../components/UnifiedControlPlane/UnifiedControlPlane.constants';
// import ordersDataMock from '../../src/components/UnifiedControlPlane/UCPComponents/ordersMock.json';
// import apiOrdersMock from '../../src/components/UnifiedControlPlane/UCPComponents/apiOrdersMock.json';

// once filtered we need to align 
const filterOrdersByStatus = (ordersData) => {

    // const allOrders = [...ordersDataMock, ...ordersData];
    
    // if we want to use local api mock data for integration
    // const allOrders = apiOrdersMock;

    // use the orders data fetched from the get orders api
    const allOrders = ordersData;

    const getOrderAssignments = (orderAssignments) => {
        return (orderAssignments && orderAssignments.length) > 0 ?
         orderAssignments.flatMap(orderAssignment =>
            orderAssignment
            // orderAssignment.satellite_operations.map(operation => ({
            //     satellite: orderAssignment.satellite,
            //     satellite_operation: operation
            // }))
        ) : [];
    };

    // const getGroundStationAssignments = (groundAssignments) => {
    //     return (groundAssignments && groundAssignments.length) > 0 ? 
    //     groundAssignments.flatMap(groundAssignment =>
    //         groundAssignment
    //         // groundAssignment.satellite_operations.map(operation => ({
    //         //     satellite: groundAssignment.satellite,
    //         //     satellite_operation: operation
    //         // }))
    //     ) : [];
    // };

    const updatedAllOrders = allOrders.map(order => {
        
        const displayAssignments = Object.keys(order.status.schedule).length > 0 ? [
            ...getOrderAssignments(order.status.schedule.orderAssignments),
            // ...getGroundStationAssignments(order.status.schedule.groundAssignments)
        ] : [];

        // ^^^^ not considering groundAssignment atm

        // const displayAssignments = Object.keys(order.status.schedule).length > 0 ? [
        //     ...order.status.schedule.orderAssignments,
        //     ...order.status.schedule.groundAssignments
        // ] : [];

        return {
            ...order,
            status: {
                ...order.status,
                schedule: {
                    ...order.status.schedule,
                    displayAssignments: displayAssignments
                }
            }
        };
    });

    const filteredOrders = {
        executed: [],
        current: [],
        pending: [],
    };

    updatedAllOrders.forEach(order => {
        switch (order.status.state) {
            case ORDER_STATUS_SERVER.EXECUTED:
                filteredOrders.executed.push(order);
                break;
            case ORDER_STATUS_SERVER.CURRENT:
                filteredOrders.current.push(order);
                break;
            case ORDER_STATUS_SERVER.PENDING:
            case ORDER_STATUS_SERVER.PREVIEW:
                filteredOrders.pending.push(order);
                break;
            default:
                break;
        }
    });

    return filteredOrders;
};

export const unifiedControlPlaneSlice = createSlice({
    name: 'ucpSlice',
    initialState: {
        scenarioId: '',
        ordersLoading: false,
        orders: {
            executed: [],
            current: [],
            pending: [],
        },
        creatingOrder: false,
        activeOrdersSection: ORDER_STATUS.CURRENT,
        showMapBoxMap: false,
        aoiCollection: {},
        uploadedCollection: {},

        allConstellation: [],
        allGroundStations: [],
        highlightedOrderAssign: {},

        fetchingSchedule: false,
        scheduleData: [],
    },
    reducers: {
        getScenarioIdRequest: (state) => {
        },
        getScenarioIdSuccess: (state, action) => {
            state.scenarioId = action.payload
        },
        getScenarioIdFailure: (state) => {
        },
        getOrdersRequest: (state) => {
            state.ordersLoading = true;
        },
        getOrdersSuccess: (state, action) => {
            state.orders = filterOrdersByStatus(action.payload);
            state.ordersLoading = false;
        },
        getOrdersFailure: (state) => {
            state.ordersLoading = false;
        },
        createOrderRequest: (state) => {
            state.creatingOrder = true;
        },
        createOrderSuccess: (state) => {
            state.creatingOrder = false;
        },
        createOrderFailure: (state) => {
            state.creatingOrder = false;
        },
        updateOrderRequest: (state) => {
            state.updatingOrder = true;
        },
        updateOrderSuccess: (state) => {
            state.updatingOrder = false;
        },
        updateOrderFailure: (state) => {
            state.updatingOrder = false;
        },
        deleteOrderRequest: (state) => {
            state.deletingOrder = true;
        },
        deleteOrderSuccess: (state) => {
            state.deletingOrder = false;
        },
        deleteOrderFailure: (state) => {
            state.deletingOrder = false;
        },
        setShowMapBoxMap: (state, action) => {
            state.showMapBoxMap = action.payload;
        },
        setUploadedFeatures: (state, action) => {
            state.uploadedCollection = action.payload;
        },
        resetUploadedFeatures: (state) => {
            state.uploadedCollection = {};
        },
        setOrdersSection: (state, action) => {
            state.activeOrdersSection = action.payload;
        },
        getConstellationRequest: (state) => { },
        getConstellationSuccess: (state, action) => {
            state.allConstellation = action.payload
        },
        getConstellationFailure: (state) => { },

        getGroundStationRequest: (state) => { },
        getGroundStationSuccess: (state, action) => {
            state.allGroundStations = action.payload
        },
        getGroundStationFailure: (state) => { },
        setHighlightedOrderAssign: (state, action) => {
            state.highlightedOrderAssign = action.payload;
        },
        resetHighlightedOrderAssign: (state) => {
            state.highlightedOrderAssign = {};
        },
        genrateScheduleRequest: (state) => {
            state.generatingSchedule = true;
        },
        genrateScheduleSuccess: (state) => {
            state.generatingSchedule = false;
        },
        genrateScheduleFailure: (state) => {
            state.generatingSchedule = false;  
        },
        getScheduleRequest: (state) => {
            state.fetchingSchedule = true;
        },
        getScheduleSuccess: (state, action) => {
            state.fetchingSchedule = false;
            // state.scheduleData = {
            //     'evaluated_orders_count': 5,
            //     'assignments' :[
            //     { assignmentTime: "2024-12-10", orderTitle: "Order B", orderAssignment: "Task 3" },
            //     { assignmentTime: "2024-12-09", orderTitle: "Order E", orderAssignment: "Task 4" },
            //     { assignmentTime: "2024-12-11", orderTitle: "Order C", orderAssignment: "Task 5" },
            //     { assignmentTime: "2024-12-08", orderTitle: "Order A", orderAssignment: "Task 2" },
            //     { assignmentTime: "2024-12-12", orderTitle: "Order D", orderAssignment: "Task 1" },
            // ]};
            state.scheduleData = action.payload;
        },
        getScheduleFailure: (state) => {
            state.fetchingSchedule = false;
            state.scheduleData = {};
            // state.scheduleData = {
            //     'evaluated_orders_count': 5,
            //     'assignments' :[
            //     { assignmentTime: "2024-12-10", orderTitle: "Order B", orderAssignment: "Task 3" },
            //     { assignmentTime: "2024-12-09", orderTitle: "Order E", orderAssignment: "Task 4" },
            //     { assignmentTime: "2024-12-11", orderTitle: "Order C", orderAssignment: "Task 5" },
            //     { assignmentTime: "2024-12-08", orderTitle: "Order A", orderAssignment: "Task 2" },
            //     { assignmentTime: "2024-12-12", orderTitle: "Order D", orderAssignment: "Task 1" },
            // ]};
        },
    },
});

export const {
    getScenarioIdRequest,
    getScenarioIdSuccess,
    getScenarioIdFailure,

    getOrdersRequest,
    getOrdersSuccess,
    getOrdersFailure,

    createOrderRequest,
    createOrderSuccess,
    createOrderFailure,

    updateOrderRequest,
    updateOrderSuccess,
    updateOrderFailure,

    deleteOrderRequest,
    deleteOrderSuccess,
    deleteOrderFailure,

    setShowMapBoxMap,
    setUploadedFeatures,
    resetUploadedFeatures,
    setOrdersSection,

    getConstellationRequest,
    getConstellationSuccess,
    getConstellationFailure,

    getGroundStationRequest,
    getGroundStationSuccess,
    getGroundStationFailure,

    setHighlightedOrderAssign,
    resetHighlightedOrderAssign,

    genrateScheduleRequest,
    genrateScheduleSuccess,
    genrateScheduleFailure,

    getScheduleRequest,
    getScheduleSuccess,
    getScheduleFailure,
    
} = unifiedControlPlaneSlice.actions;

export default unifiedControlPlaneSlice.reducer;