// @ts-nocheck
import React, { useEffect, useState } from "react";
import MissionOrbit from "./OrbitVisualisation/MissionOrbit";
import { Box, CircularProgress } from "@mui/material";

import FeaturesBar from "./UCPComponents/FeaturesBar";
import OrderCreationModal from "./UCPComponents/OrderCreationModal";
import BottomBarOrderAnalytics from "./UCPComponents/BottomBarOrderAnalytics";
import PolicyBar from "./UCPComponents/PolicyBar";
import PolicyCreationModal from "./UCPComponents/PolicyCreationModal";

import AnalyticsFlow from "./UCPComponents/AnalyticsFlow/AnalyticsFlow";
import OrdersView from "./UCPComponents/Orders/OrdersView";
import { useDispatch, useSelector } from "react-redux";
import MapboxView from "./UCPComponents/MapboxView/MapboxView";

import UnifiedControlPlaneService from "./UnifiedControlPlane.service";
import { useParams } from "react-router-dom";
import { ucp_visualization_presentation } from "./Utils";
import { ORDER_STATUS } from "./UnifiedControlPlane.constants";

const UnifiedControlPlane = () => {
  const params = useParams()
  const dispatch = useDispatch();

  const { mission_id } = params;

  const showMapBoxMap = useSelector(state => state.unifiedControlPlane.showMapBoxMap);
  const activeOrdersSection = useSelector(state => state.unifiedControlPlane.activeOrdersSection);

  const scenarioId = useSelector(state => state.unifiedControlPlane.scenarioId);

  const orders = useSelector(state => state.unifiedControlPlane.orders);
  const allGroundStations = useSelector(state => state.unifiedControlPlane.allGroundStations);
  const allConstellation = useSelector(state => state.unifiedControlPlane.allConstellation);

  const [isOrdersBarVisible, setIsOrdersBarVisible] = useState(true);
  const [isOrderCreationModal, setIsOrderCreationModal] = useState(false);
  const [isComparisonModal, setIsComparisonModal] = useState(false);
  const [isOrdersBarFeaturesList, setOrdersBarFeaturesList] = useState(false);
  const [isSubOrdersBarFeaturesList, setIsSubOrdersBarFeaturesList] = useState(false);
  const [isExpandedOrdersBar, setIsExpandedOrdersBar] = useState(false);
  const [bottomBarComponent, setBottomBarComponent] = useState({ Order: true, Analytics: false });
  const [isPolicyBarVisible, setIsPolicyBarVisible] = useState(true);
  const [isNewPolicyCreationModal, setIsNewPolicyCreationModal] = useState(false);
  const [ucpViewer, setUcpViewer] = useState()

  const props = {
    isOrdersBarVisible,
    isOrderCreationModal,
    isComparisonModal,
    isOrdersBarFeaturesList,
    isSubOrdersBarFeaturesList,
    isExpandedOrdersBar,
    bottomBarComponent,
    isNewPolicyCreationModal,
    isPolicyBarVisible,
    setIsSubOrdersBarFeaturesList,
    setIsOrdersBarVisible,
    setIsOrderCreationModal,
    setIsComparisonModal,
    setOrdersBarFeaturesList,
    setIsExpandedOrdersBar,
    setBottomBarComponent,
    setIsNewPolicyCreationModal,
    setIsPolicyBarVisible,
  };

  const getListOfAois = (order) => {
    let listOfAOI = []

    order?.map((currentOrder, index) => {
      if (currentOrder?.spec?.geofeature?.features[0]?.geometry?.type === "Polygon") {
        listOfAOI.push({
          name: currentOrder?.spec?.name,
          areaOfInterestId: currentOrder?.spec?.geofeature?.features[0]?.id,
          polygon: currentOrder?.spec?.geofeature?.features[0]?.geometry?.coordinates
        })
      }
      else if (currentOrder?.spec?.geofeature?.features[0]?.geometry?.type === "Point") {
        listOfAOI.push({
          name: currentOrder?.spec?.name,
          areaOfInterestId: currentOrder?.spec?.geofeature?.features[0]?.id,
          point: currentOrder?.spec?.geofeature?.features[0]?.geometry?.coordinates
        })
      }
      currentOrder?.status?.coverage?.coveredStrips?.map((strip, index) => {
        listOfAOI.push(
          {
            name: index,
            areaOfInterestId: index,
            polygon: strip.geoJSON.coordinates
          }
        )
      })
    })
    return listOfAOI;
  }

  const setActiveAoiDataSource = () => {
    switch (activeOrdersSection) {
      case ORDER_STATUS.CURRENT:
        ucp_visualization_presentation().show_datasource(ucpViewer, 'Current_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Executed_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Pending_Order')
        break;
      case ORDER_STATUS.EXECUTED:
        ucp_visualization_presentation().show_datasource(ucpViewer, 'Executed_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Current_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Pending_Order')
        break;
      case ORDER_STATUS.PENDING:
        ucp_visualization_presentation().show_datasource(ucpViewer, 'Pending_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Executed_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Current_Order')
        break;
      default:
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Pending_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Executed_Order')
        ucp_visualization_presentation().hide_datasource(ucpViewer, 'Current_Order')
        break
    }
  }

  useEffect(() => {
    dispatch(UnifiedControlPlaneService.getMissionMoellingModels(mission_id))
  }, [dispatch]);

  useEffect(() => {
    if (scenarioId) {
      dispatch(UnifiedControlPlaneService.getConstellationsFromMM(mission_id, scenarioId))
      dispatch(UnifiedControlPlaneService.getGroundStationsFromMission(mission_id))
      dispatch(UnifiedControlPlaneService.getOrders());
    }
  }, [scenarioId, ucpViewer])

  useEffect(() => {
    ucp_visualization_presentation().init_all_aois(getListOfAois(orders?.current), ucpViewer, 'Current_Order')
    ucp_visualization_presentation().init_all_aois(getListOfAois(orders?.executed), ucpViewer, 'Executed_Order')
    ucp_visualization_presentation().init_all_aois(getListOfAois(orders?.pending), ucpViewer, 'Pending_Order')
  }, [orders.current, orders.executed, orders.pending, ucpViewer])

  useEffect(() => {
    if (ucpViewer && ucpViewer.dataSources.length > 0) {
      setActiveAoiDataSource()
    }
  }, [activeOrdersSection, ucpViewer])

  useEffect(() => {
    if (allGroundStations && ucpViewer) {
      ucp_visualization_presentation().init_all_gs(allGroundStations, ucpViewer)
    }
    if (allConstellation && ucpViewer) {
      ucp_visualization_presentation().init_all_satellites(allConstellation, ucpViewer)
    }
  }, [allGroundStations, allConstellation])

  return (
    <>
      {(!showMapBoxMap && bottomBarComponent.Order) && <MissionOrbit setUcpViewer={setUcpViewer} />}
      {isOrdersBarVisible && bottomBarComponent.Order &&
        <OrdersView props={props} ucpViewer={ucpViewer} scenarioId={scenarioId} mission_id={mission_id} />}
      {showMapBoxMap && <MapboxView />}
      {isOrderCreationModal && bottomBarComponent.Order && <OrderCreationModal props={props} />}
      <BottomBarOrderAnalytics props={props} />
      {props.isNewPolicyCreationModal && <PolicyCreationModal props={props} />}

      {bottomBarComponent.Analytics && <AnalyticsFlow props={props} />}
    </>
  );
};

export default UnifiedControlPlane;
